<template>
  <div v-if="!loadingCandidate">
    <keep-alive>
      <card :title="pageTitle" head :body="false" foot>
        <!-- 
        <alert v-if="candidate.archivedTrainingSessions.length" type="warning" class="m-2">
          <h4 v-t="'candidates.archived.collections_archived.title'"></h4>
          <span v-if="candidate.collections.length == 0"
            v-t="'candidates.archived.collections_archived.only_archived_message'"></span>
          <span v-else v-t="'candidates.archived.collections_archived.message'"></span>
          <template v-for="ats in candidate.archivedTrainingSessions    ">
            <div class="card-body">
              <h5
                v-t="{ path: 'candidates.archived.collections_archived.training_session', args: { title: ats.title } }">
              </h5>
              <row>
                <column>
                  <p
                    v-t="{ path: 'candidates.archived.collections_archived.archived_at', args: { archived_at: $d(new Date(ats.pivot.json.archived_at), 'short', 'en-GB') } }">
                  </p>
                </column>
                <column>

                  <p
                    v-t="{ path: 'candidates.archived.collections_archived.status', args: { status: ats.pivot.json.status } }">
                  </p>
                </column>
                <column>

                  <p
                    v-t="{ path: 'candidates.archived.collections_archived.reason', args: { reason: ats.pivot.json.reason } }">
                  </p>

                </column>
              </row>



            </div>
          </template>
</alert>
<div v-if="training_session.description" class="card-body fs-5" v-html="training_session.description"></div>
<transition name="fade" mode="in-out">
  <scope v-if="hasInitialAssessment" :ia="training_session.initial_assessment"
    :cia="initialAssessment(training_session.initial_assessment.case_id)" v-slot="{ ia, cia }" class="">
    <card head :body="false" :title="$t('candidates.initial_assessment.title')" class="m-2 text-bg-primary">
      <div class="m-0 card-body p-1 text-bg-primary">
        <row>
          <column :sizes="{ md: 3 }" class="order-2 order-md-1">
            <div class="d-flex flex-column case-collection rounded-50 m-auto my-2 text-bg-info"
              :style="{ 'max-width': cia && cia.pivot.score !== null ? '100px' : '200px' }">

              <p v-if="!cia.pivot || cia.pivot.score == null" class="fs-5 fw-bold text-center">Attempt
                Competency Test</p>
              <router-link class="align-self-center" :key="ia.id"
                :to="{ name: isAdmin ? 'admin.initial_assessment' : 'initial_assessment', params: { case_id: ia.case_id } }">
                <donut label="" :percent="cia ? cia.pivot.score : null" :inner-radius="20" :outer-radius="24"
                  :percent-pass="50" :showStartIcon="true" :enlarge-when-updated="true" />
              </router-link>
            </div>
          </column>
          <column :sizes="{ md: 9 }" class="order-1 order-md-2 d-flex flex-column">


            <template v-if="!cia.pivot || cia.pivot.score == null">
                      <p v-t="'candidates.initial_assessment.p1'"></p>
                      <i18n path="candidates.initial_assessment.p2" class="mb-4">
                        <template #email>
                          <a :href="'mailto:bvasvdi@ndorms.ox.ac.uk'" class="text-white">bvasvdi@ndorms.ox.ac.uk</a>
                        </template>
            </i18n>
            <p v-t="'candidates.initial_assessment.p3'" class="fw-bold mt-4"></p>
            </template>
            <template v-if="cia.pivot && cia.pivot.score != null">
                      <p v-if="cia.pivot.score > 50" class="fs-5 my-auto" v-t="'candidates.initial_assessment.pass'">
                      </p>
                      <p v-if="cia.pivot.score < 50" class="fs-5 my-auto" v-t="'candidates.initial_assessment.fail'">
                      </p>
                    </template>
          </column>
        </row>


      </div>


    </card>
  </scope>

</transition>
<div class="card-body" v-if="training_session.meta.show_resources">
  <btn is-router-link :outline="false" :to="{ name: 'learning_resources' }">
    <fa icon="play-circle" /> View the learning resources
  </btn>
  <alert v-if="candidate.collections.length == 0" class="my-2">
    <span v-t="'candidates.no_cases_assigned'"></span>
  </alert>
</div>

-->
        <list-group flush>
          <template v-for="collection in candidate.collections">
            <list-group-item :key="collection.id" v-if="shouldShowCollection(collection)">
              <h3 v-if="collections" v-text="getCollection(collection.collection_id)?.title"></h3>
              <h5 v-if="collections" v-html="getCollection(collection.collection_id)?.description"></h5>
              <row class="align-items-center pb-4">
                <column :sizes="{ xl: 10, lg: 10, md: 9, sm: 12 }" class="align-self-middle">
                  <transition-group tag="div" class="case-collection" name="fade" appear>
                    <div v-for="(ccase, n) in cases(collection)" :key="ccase.id"
                      v-popover="getCasePopover(ccase, collection)" class="case col-lg-1 col-sm-3 p-1">

                      <router-link :to="{ name: candidateCaseRoute, params: { case_id: ccase.case_id } }">
                        <donut :label="(n + 1).toString()" :percent="ccase.score" :inner-radius="20" :outer-radius="24"
                          :percent-pass="50" :showStartIcon="nextUnstartedCase == ccase" :enlarge-when-updated="true" />
                      </router-link>
                      <btn @click.native="swapWithReserveCase(ccase)" v-if="caseCanBeSwapped(ccase, collection)"
                        :outline="false" btn-class="secondary" size="sm" class="replaceCaseBtn">
                        <fa icon="arrow-down" />
                        <fa icon="arrow-up" />
                      </btn>
                    </div>
                  </transition-group>
                  <div v-if="collectionHasReserveCases(collection)">
                    <h4 class="mt-2" v-if="isCandidate && replacedCases(collection).length">
                      {{ $t('candidates.replaced_cases') }}</h4>
                    <h4 class="mt-2" v-if="isStaff">Reserve Cases:</h4>
                    <transition-group
                      v-if="replacedCases(collection).length || isStaff && getCollectionReserveCases(collection).length"
                      class="case-collection replaced p-1 d-inline-block" name="fade" appear>
                      <a v-if="isStaff" @click="showCaseReplaceBtns(ccase)" class="case col-lg-1 col-sm-3 p-1"
                        v-for="(ccase, n) in getCollectionReserveCases(collection)" :key="ccase.id"
                        v-popover="getCasePopover(ccase, collection)">
                        <donut v-if="!ccase.replaced" :class="{ 'selected': ccase == selectedReserveCase }"
                          :label="(n + 1).toString()" :percent="ccase.score" :inner-radius="20" :outer-radius="24"
                          :percent-pass="50" :enlarge-when-updated="true" />
                        <donut v-else :label="(n + 1).toString()" :percent="ccase.replaced.score" :inner-radius="20"
                          :outer-radius="24" :percent-pass="50" :enlarge-when-updated="true" />
                      </a>

                    </transition-group>
                  </div>
                </column>
                <column :sizes="{ xl: 2, lg: 2, md: 3, sm: 6 }" :offset="{ sm: 3, md: 0 }">
                  <transition name="fade" appear>
                    <donut class="m-sm-auto" :percent="collectionScore(collection)" :sections="collection.cases.length"
                      :percent-pass="percentPassType(collection)" :hide-pass-fail="!collectionComplete(collection)" />
                  </transition>
                </column>
              </row>
            </list-group-item>
          </template>

        </list-group>
        <template #footer-right>
          <btn v-if="isAdmin" @click.native="showArchiveTrainingSessionForm = true" outline class="py-0" theme="danger"
            :disabled="showArchiveTrainingSessionForm">
            <fa icon="user-xmark" /> Archive Training Session
          </btn>
        </template>

        <modal :show="showArchiveTrainingSessionForm" @close="showArchiveTrainingSessionForm = false"
          :title="$t('admin.training_session.archive.title')">
          <p v-t="'admin.training_session.archive.message'"></p>
          <form-group :di="{ name: 'archivedStatus', label: $t('admin.training_session.archive.status') }" cols="4|8"
            v-slot="{ di }">
            <btn-group v-model="archivedTrainingSessionForm.archivedStatus">
              <btn true-value="Certified" theme="success" outline>Certified</btn>
              <btn true-value="Cancelled" theme="danger" outline>Cancelled</btn>
            </btn-group>
          </form-group>
          <form-group :di="{ name: 'archivedReason', label: $t('admin.training_session.archive.reason') }" cols="4|8"
            v-slot="{ di }">
            <textbox v-model="archivedTrainingSessionForm.archivedReason"></textbox>
          </form-group>
          <template #footer-inner="{ close }">
            <btn @click.native="close" outline class="py-0">
              <fa icon="times" /> Cancel
            </btn>
            <btn @click.native="confirmArchiveCandidatesTrainingSession(); close()" outline class="py-0" theme="danger">
              <fa icon="user-xmark" /> Archive Training Session
            </btn>
          </template>
        </modal>
      </card>
    </keep-alive>
    <!-- <card v-if="isAdmin" class="mt-4" :title="$t('admin.audit.candidate', { name: candidate.name })" head :body="false">
      <template #header-right>
        <btn @click.native="showCandidateAudit = !showCandidateAudit" outline class="py-0">
          <fa icon="chevron-down" :rotation="showCandidateAudit ? 180 : null" style="transition: transform .3s ease;">
          </fa>
        </btn>
      </template>
      <collapse :show="showCandidateAudit">
        <list-group>
          <list-group-item v-for="(item) in getFullAuditSummary()">
            {{ item.title }}:
            <list-group horizontal="lg">
              <list-group-item :key="'lgi_' + item.ccase_id + '_' + n" v-for="(a, n) in item.audit">
                {{ a }}
              </list-group-item>
            </list-group>
          </list-group-item>
        </list-group>
      </collapse>
    </card> -->

  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import case_collections from '~/mixins/case-collections.js'
import { reduce } from 'lodash'

export default {
  middleware: 'auth',
  mixins: [case_collections],
  metaInfo() {
    return { title: this.$t('trainingSession.candidate_home', { user: this.candidate.name }) }
  },
  watch: {
    '$route': {
      handler() {
        this.clearBVCaseData()
        if (this.isCandidate) this.getLoggedInCandidate({ params: this.params })
        else this.getCandidateOverview({ params: this.params })
      },
      immediate: true
    },
  },
  data() {
    return {
      selectedReserveCase: false,
      showCandidateAudit: false,
      showArchiveTrainingSessionForm: false,
      params: {
        ...this.$route.params,
        include: 'user,collections,training_session,audits,archivedTrainingSessions',
        archivedTrainingSessions: true
        // append: 'issues',
      },
      archivedTrainingSessionForm: {
        archivedReason: '',
        archivedStatus: null,

      }
    }
  },
  computed: {
    ...mapState('editor', ['user', 'mode']),
    ...mapState('resources/candidate', ['candidate', 'collections', 'training_session', 'mode', 'loadingCandidate']),
    ...mapGetters('editor', ['nextUnstartedCase']),
    ...mapGetters('auth', ['isCandidate', 'isStaff', 'isAdmin']),
    pageTitle() {
      if (this.training_session && this.training_session.title) return this.training_session.title
      return 'Home'
    },
    candidateCaseRoute() {
      if (this.isCandidate) return 'candidates_case'
      if (this.isStaff || this.isAdmin) return 'candidate_case'
    },
    hasInitialAssessment() {
      return this.training_session.meta.has_initial_assessment == true
    },
    canSeeCollections() {
      return !this.hasInitialAssessment
    }
  },
  methods: {
    // ...mapActions('editor', ['getCandidateOverview', 'clearBVCaseData', 'replaceCandidateCase']),
    ...mapActions('resources/candidate', ['getLoggedInCandidate', 'getCandidateOverview', 'clearBVCaseData', 'replaceCandidateCase', 'archiveTrainingSession']),
    initialAssessment(case_id) {
      return this.candidate.initial_assessment.find((ia) => ia.case_id == case_id)
    },
    collectionScore(collection) {
      var started = false;
      var score =
        reduce(this.cases(collection), (a, m, i, p) => { if (m.score != null) started = true; return a + m.score; }, null);
      if (started) return score / this.cases(collection).length
    },
    showCaseReplaceBtns(ccase) {
      if (this.selectedReserveCase == ccase) this.selectedReserveCase = false
      else this.selectedReserveCase = ccase
    },
    getCasePopover(ccase, collection, reserve = false) {
      var myCase = (ccase.replaced) ? ccase.replaced : ccase

      var html = this.getCaseAuditSummary(myCase.audits).join('<br/>');
      if (ccase.replaced) html += '<br/>' + this.getCaseReplacementSummary(ccase, collection).join('<br/>');
      return { event: 'hover', title: this.getBVCaseName(myCase, collection), html }
    },
    getCaseAuditSummary(audits) {
      var summary = []
      if (audits && audits.length) {
        for (let index = 0; index < audits.length; index++) {
          const a = audits[index];
          if (a.event == "created") {
            summary.push(this.$t('admin.audit.candidate_case.created', { date: this.$d(new Date(a.created_at), 'numeric_datetime', 'en-GB') }))
          } else {
            summary.push(this.$t('admin.audit.candidate_case.updated', { user: this.candidate.user_id == a.user_id ? 'candidate' : a.user_id, date: this.$d(new Date(a.updated_at), 'numeric_datetime', 'en-GB') }))
          }

        }

      }
      return summary
    },
    getCaseReplacementSummary(ccase, collection) {
      var summary = []
      if (ccase.replaced) {
        var replaced = this.getCandidateCase(ccase.replaced.case_id, collection)
        var replacedCaseName = this.getBVCaseName(ccase, collection)
        summary.push(this.$t('admin.audit.candidate_case.replaced', { case: replacedCaseName }))
      }
      return summary
    },
    getFullAuditSummary() {
      var summary = []
      for (let collIndex = 0; collIndex < this.candidate.collections.length; collIndex++) {
        const coll = this.candidate.collections[collIndex];
        for (let caseIndex = 0; caseIndex < coll.cases.length; caseIndex++) {
          const ccase = coll.cases[caseIndex];
          var title = this.getBVCaseName(ccase, coll)
          var audit = this.getCaseAuditSummary(ccase.audits)
          var summaryRow = { ccase_id: ccase.id, title, audit }
          if (audit.length) summary.push(summaryRow);
        }


      }
      return summary
    },
    percentPassType(collection) {
      if (this.getCollection(collection.collection_id).title.includes('PVAS')) return 80
      if (this.getCollection(collection.collection_id).title.includes('PVDI')) return 80
      if (this.getCollection(collection.collection_id).title.includes('BVAS')) return 85
      if (this.getCollection(collection.collection_id).title.includes('VDI')) return 75

    },
    shouldShowCollection(collection) {
      var requiresIAPass = false
      var fullCollection = this.getCollection(collection.collection_id)
      if (this.hasInitialAssessment) {
        if (fullCollection.pivot.meta) {
          requiresIAPass = fullCollection.pivot.meta.IA_Pass
        }
        var IA = this.initialAssessment(this.training_session.initial_assessment.case_id)
        var passedIA = IA && IA.pivot.score >= 50
        if (passedIA) return requiresIAPass
      }
      return requiresIAPass === false
    },
    caseCanBeSwapped(ccase, collection) {
      var isSelected = this.selectedReserveCase && this.selectedReserveCase.pivot.collection_id == collection.collection_id
      if (this.selectedReserveCase.replaced && this.selectedReserveCase.replaced.json) {
        var alreadyReplaced = (this.selectedReserveCase.replaced && this.selectedReserveCase.replaced.json.replaced_with == ccase.case_id)
        return isSelected && alreadyReplaced
      }
      return isSelected
    },
    confirmArchiveCandidatesTrainingSession() {
      this.archiveTrainingSession({ candidate_id: this.candidate.id, training_session_id: this.training_session.id, ...this.archivedTrainingSessionForm })
        .then(() => {
          this.getCandidateOverview({ params: this.params })
        })
    }
  }
}

</script>
<style lang="scss"></style>
